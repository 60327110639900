import { useTranslation } from 'next-i18next'

import { useLanguageAndCountry } from 'src/hooks/useLanguageAndCountry'
import { inferLanguageLabelByLanguageCode } from 'src/utils/plain/inferLanguageLabelByLanguageCode'

import { DrawerFacet } from '@/components/DrawerFacet'

export const PanelEntries = () => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.filtersPanel.filters',
  })
  const { t: tDA } = useTranslation('dam-assets')

  const { languageCode } = useLanguageAndCountry()

  return (
    <>
      <DrawerFacet
        attribute="documentTypes"
        includedAttrsInTrackResetFilters={['documentTypes']}
        title={t('documentType')}
        transformItems={(items) =>
          items
            // document type labels are codes for types, not actual translations
            .map((item) => ({ ...item, label: tDA(`assetCategories.${item.label}`) }))
            // sort items alphabetically
            .sort((a, b) => a.label.localeCompare(b.label))
        }
      />
      <DrawerFacet
        attribute="productCategories.lvl0"
        includedAttrsInTrackResetFilters={['division']}
        title={t('productCategory')}
        withlanguageCodeSuffix
      />
      <DrawerFacet
        attribute="fieldsOfApplication"
        includedAttrsInTrackResetFilters={['division']}
        title={t('fieldOfApplication')}
        withlanguageCodeSuffix
      />
      <DrawerFacet
        attribute="areasOfApplication"
        includedAttrsInTrackResetFilters={['division']}
        title={t('areaOfApplication')}
        withlanguageCodeSuffix
      />
      <DrawerFacet
        attribute="division"
        includedAttrsInTrackResetFilters={['division']}
        title={t('division')}
        transformItems={(items) =>
          // division labels are codes for divisions, not actual translations
          items.map((item) => ({ ...item, label: tDA(`divisions.${item.label}`) }))
        }
      />
      <DrawerFacet
        attribute="language"
        includedAttrsInTrackResetFilters={['language']}
        title={t('language')}
        transformItems={(items) =>
          items.map((item) => ({
            ...item,
            // e.g. converts label `da` to `Danish` if `languageCode` is `en`
            label: inferLanguageLabelByLanguageCode(languageCode)(item.label),
          }))
        }
      />
    </>
  )
}
