import { useRef } from 'react'

import type { Theme } from '@mui/material'
import { Box, Divider, Drawer, Stack, Toolbar } from '@mui/material'
import type { SxProps } from '@mui/system'

import { SortByDropdown } from '@/components/SortByDropdown'
import { setFiltersDrawerOpen, useGlobalParamsContext } from '@/contexts/GlobalParamsContext'

import { PanelEntries } from './PanelEntries'
import { PanelFooter } from './PanelFooter'
import { PanelHeader } from './PanelHeader'

const style = {
  container: {
    width: '100%',
    paddingBottom: '56px',
    minHeight: '100vh',
    margin: 'auto',
    px: 2,
    py: 3,
    gap: 2,
  } as SxProps<Theme>,
}

// TODO recreate & simplify
export const NondesktopFilters = () => {
  const scrollToRef = useRef<null | HTMLDivElement>(null)
  const { state, dispatch } = useGlobalParamsContext()
  const { filtersDrawerOpen } = state

  const closeDrawer = () => {
    dispatch(setFiltersDrawerOpen(false))
    scrollToRef?.current?.scrollIntoView()
  }

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      open={filtersDrawerOpen}
      onClose={closeDrawer}
      // removes the extra space around the empty `div` this component creates even when it's not open
      sx={{ my: -2 }}
    >
      <Box component="aside" sx={{ width: { xs: '100vw', sm: '375px' } }} minHeight="100vh">
        <PanelHeader onCloseDrawer={closeDrawer} />
        <Toolbar />
        <Stack ref={scrollToRef} sx={style.container}>
          <Box>
            <SortByDropdown dataCyPrefix="sort-by-nondesktop" />
          </Box>
          <Divider />
          <PanelEntries />
        </Stack>
        <PanelFooter onShowResults={closeDrawer} />
      </Box>
    </Drawer>
  )
}
