import { useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { grey } from '@knauf-group/ct-designs'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { useAnalyticsTrackResetFilters } from 'src/hooks/analytics/useAnalyticsTracking'
import { useFacet } from 'src/hooks/useFacet'
import { createAccordionTitleSuffix } from 'src/utils/frontend/createAccordionTitleSuffix'
import { getLanguageAndCountry } from 'src/utils/getLanguageAndCountry'

import type { RefinementListItem } from '@/types'

type DrawerFacetProps = {
  attribute: string
  includedAttrsInTrackResetFilters: string[]
  title: string
  transformItems?: (items: RefinementListItem[]) => RefinementListItem[]
  withlanguageCodeSuffix?: boolean
}

export const DrawerFacet: React.FC<DrawerFacetProps> = ({
  attribute,
  includedAttrsInTrackResetFilters: includedAttributes,
  title,
  transformItems = (x) => x,
  withlanguageCodeSuffix = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const { t } = useTranslation('download-center', { keyPrefix: 'mainPage.filtersPanel' })

  const { locale } = useRouter()
  const { languageCode } = getLanguageAndCountry(locale)

  const attributeToUse = attribute + (withlanguageCodeSuffix ? `.${languageCode}` : '')

  const {
    canRefine,
    clearRefinements,
    items: originalItems,
    handleItemSelect,
  } = useFacet({
    attribute: attributeToUse,
  })

  const items = transformItems(originalItems)

  const { trackResetFiltersEvent } = useAnalyticsTrackResetFilters({ includedAttributes })

  const onClickReset = () => {
    // TODO should we send the translation of the text?
    trackResetFiltersEvent(t('buttons.reset'))
    clearRefinements()
  }

  const titleSuffix = createAccordionTitleSuffix(items)

  // TODO update dataCy attrs
  // TODO check styles. all is needed?
  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
      sx={{
        p: 0,
        borderBottomWidth: '0px !important',
        '&.MuiAccordion-root:before': { position: 'unset' },
      }}
      data-cy={attribute}
    >
      {/* title */}
      <AccordionSummary
        expandIcon={
          isExpanded ? (
            <RemoveOutlinedIcon fontSize="small" />
          ) : (
            <AddOutlinedIcon fontSize="small" />
          )
        }
        sx={{ borderLeft: `2px solid ${grey[200]}`, pr: 1 }}
      >
        <Typography variant="body3bold">{title + titleSuffix}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <List sx={{ border: 'none' }} data-cy={attribute}>
          {/* "No filter results" text when needed */}
          {items.length === 0 && (
            <ListItem disablePadding>
              <Box px={2} py={1}>
                <Typography variant="body3dense" noWrap data-cy={`${attribute}__no-results`}>
                  {t('noResults')}
                </Typography>
              </Box>
            </ListItem>
          )}
          {/* All options in the filter */}
          {items.map(({ value, label, isRefined }) => (
            <ListItem disablePadding key={`${value}`}>
              <ListItemButton
                sx={{ pl: 0 }}
                role={undefined}
                onClick={() => handleItemSelect(value)}
                data-cy={`${attribute}__button__${value}`}
              >
                <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
                  <Checkbox
                    checked={isRefined}
                    disableRipple
                    data-cy={`${attribute}__checkbox__${value}`}
                    color="secondary"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      color="text.primary"
                      variant="body3dense"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      display="flex"
                    >
                      {label}
                    </Typography>
                  }
                  data-cy={`${attribute}__label__${value}`}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* "Reset filter" button */}
        <Button
          size="small"
          variant="outlined"
          color="primary"
          sx={{ width: '100%', padding: '8px 16px' }}
          disabled={!canRefine}
          onClick={onClickReset}
          data-cy={`${attribute}-reset-filter`}
        >
          {t('buttons.reset')}
        </Button>
      </AccordionDetails>
    </Accordion>
  )
}
